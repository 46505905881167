import {newNotice} from './noticeToast';

export default function () {
  $(document).ready(function() {
    const $responseMessageContainer = $('.nf-response-msg');

    // Create a MutationObserver instance
    const nfMessageObserver = new MutationObserver(function (mutationsList) {
      for (const mutation of mutationsList) {
        if (mutation.type === 'childList' && mutation.addedNodes.length > 0) {
          // The DOM has been modified, and new nodes have been added
          const $this = $(mutation.target);
          const newText = $this.text();

          if (newText) {
            newNotice(newText, 'success', 10000);
          }
        }
      }
    });

    // Start observing the target node with the specified configuration
    if ($responseMessageContainer.length){
      nfMessageObserver.observe($responseMessageContainer[0], { childList: true });
    }

    //const $submitButton = $('.nf-element[type=submit]');

    /*$submitButton.on('click', function (e) {
      e.preventDefault();
    })*/
  });
}
