import 'custom-event-polyfill';

// Import everything from autoload
import './autoload/_bootstrap';
//import './autoload/_jquery';

// import local dependencies
import Router from './util/Router';
import common from './routes/common';

import './components/cookieData';

import './util/helper';

/** Populate Router instance with DOM routes */
const routes = new Router({
  // All pages
  common,
});

// Load Events
jQuery(document).ready(() => routes.loadEvents());
