import Cookies from 'js-cookie';
$(function ($) {
    $('.js-cookieNote_reject').click(function () {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            'event': 'gtm_cookie_update',
            //'ad_storage': 'denied', //granted or denied based on user choice to ads related cookies
            'analytics_storage': 'denied', //granted or denied based on user choice to analytics                    related cookies
            //'allow_google_signals': 'false', //if user declined ads storage, please set this parameter to false, if granted = true.
            //'personalization_storage': 'denied' //optional
        });
    })
    $('.js-cookieNote_accept').click(function () {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            'event': 'gtm_cookie_update',
            //'ad_storage': 'granted', //granted or denied based on user choice to ads related cookies
            'analytics_storage': 'granted', //granted or denied based on user choice to analytics                    related cookies
            //'allow_google_signals': 'false', //if user declined ads storage, please set this parameter to false, if granted = true.
            //'personalization_storage': 'denied' //optional
        });
    })

    window.dataLayer = window.dataLayer || [];

    let cookiesStatus = Cookies.get('cookie_policy') || window.localStorage.getItem('cookie_policy');
    let analyticsStorageStatus;
    if (!window.hasCookieNotification || cookiesStatus === 'accept') {
        analyticsStorageStatus = 'granted';
    } else {
        analyticsStorageStatus = 'denied';
    }
    window.analyticStorageStatus = analyticsStorageStatus;
    window.dataLayer.push({
        'event': 'gtm_cookie_consent',
        //'ad_storage': 'denied',
        'analytics_storage': analyticsStorageStatus,
        //'allow_google_signals': 'false', //if user declined ads storage, please set this parameter to false, if granted = true.
        //'personalization_storage': 'denied'
    });
});
