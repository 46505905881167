import {newNotice} from '../noticeToast';
import {increaseSessionCartItemCount, reduceSessionCartItemCount} from './wcHelper';

const $isBillingCompanyInput = $('input#billing_is_company');
const $vatNrInputContainer = $('.validate-eu-vat-number');
let toggleIsCompanyFields = function () {
  let $forCompaniesFields = $('.for-companies, #billing_eu_vat_number_field');
  if (!$forCompaniesFields.length){
    return;
  }

  let $inputs = $forCompaniesFields.find('input, select');
  if ($isBillingCompanyInput.is(':checked')) {
    $forCompaniesFields.show();
    $inputs.attr('autocomplete', 'on');
    $inputs.prop('disabled', false);
  } else {
    $forCompaniesFields.hide();
    $inputs.attr('autocomplete', 'off');
    $inputs.prop('disabled', true);
  }
}

let toggleVatFieldBasedOnCountry = function (countryCode) {
  let $billingEuVatNrField = $('#billing_eu_vat_number_field');
  let $inputs = $billingEuVatNrField.find('input');
  if (!window.theme.euCountries.includes(countryCode)) {
    $inputs.prop('disabled', true);
    $inputs.attr('autocomplete', 'off');
    $billingEuVatNrField.hide();
  } else {
    $inputs.attr('autocomplete', 'on');
    $inputs.prop('disabled', false);
    $billingEuVatNrField.show();
  }
}

let fillFieldsWithSessionData = function () {
  const map = {
    '&': '&amp;',
    '<': '&lt;',
    '>': '&gt;',
    '"': '&quot;',
    '\'': '&#x27;',
    '/': '&#x2F;',
  };
  const reg = /[&<>"'/]/ig;
  let autofillStorage = sessionStorage.getItem('checkout-autofill-storage')

  if (!$('.js-session-autofill-checkout').length || !autofillStorage) {
    return;
  }
  autofillStorage = JSON.parse(autofillStorage);
  $.each(autofillStorage, function () {
    let $input = $('input[name="' + this.name + '"].js-session-autofill-checkout')
    if (this.type === 'select') {
      $input = $('select[name="' + this.name + '"].js-session-autofill-checkout')
    }
    if (!$input.length) {
      return true;
    }

    if (this.type === 'select' || this.type === 'text' || this.type === 'email') {
      $input.val((this.val).replace(reg, (match) => (map[match])));
      $input.change()
    }
    if (this.type === 'checkbox' && this.val !== $input.prop('checked')) {
      $input.trigger('click');
    }
  })
}

let cacheCheckoutUserInputInSession = function (name, type, val) {

  if (type !== 'text' && type !== 'email' && type !== 'checkbox' && type !== 'select') {
    return;
  }

  let autofillStorage = sessionStorage.getItem('checkout-autofill-storage')
  if (!autofillStorage) {
    autofillStorage = [];
  } else {
    autofillStorage = JSON.parse(autofillStorage);
  }

  let storageObject = {};
  if (name && type) {
    storageObject = {
      name: name,
      type: type,
      val: val,
    };
  }

  if (storageObject && !$.isEmptyObject(storageObject)) {
    let index = autofillStorage.findIndex(item => item.name === name);
    if (storageObject.val) {
      if (index === -1) {
        autofillStorage.push(storageObject)
      } else {
        autofillStorage[index] = storageObject
      }
      sessionStorage.setItem('checkout-autofill-storage', JSON.stringify(autofillStorage))
    } else if (index !== -1) {
      autofillStorage.splice(index, 1)
      sessionStorage.setItem('checkout-autofill-storage', JSON.stringify(autofillStorage))
    }
  }
}

let updateCheckoutSession = function (key, value) {
  let data = {
    action: 'wc_ajax_update_checkout_session',
    key: key,
    value: value,
  };

  // Trigger event.
  return $.ajax({
    type: 'post', url: window.theme.ajaxUrl, data: data,
  });
};

export function initCheckout() {
  let addWcErrorsAsNotices = function ($wcErrorContainer) {
    $wcErrorContainer.hide();
    let errors = $wcErrorContainer.html()
    if (!errors) {
      return;
    }
    errors = errors.replace('</li>', '</li><br>')
    errors = errors.split('<br>')

    $wcErrorContainer.remove();
    $.each(errors, function (id, error) {
      //console.log(error)
      newNotice(error, 'error');
    })
  }

  $(document).ready(function () {
    let $vatProcess = $('#alg_wc_eu_vat_progress');
    let vatStateText = $vatProcess.text();

    if (window.theme.toDatalayerOnLoad){
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push(window.theme.toDatalayerOnLoad);
    }

    $(document.body).on('init_checkout', function () {
      console.log('init checkout');
    });

    $(document.body).on('checkout_error', function () {
      console.log('checkout error');
      addWcErrorsAsNotices($('.woocommerce-error')) // Observer should handle it.
    });

    $(document.body).on('update_checkout', function () {
      if ($isBillingCompanyInput.is(':checked')) {
        toggleVatFieldBasedOnCountry($('.js-hide-vat-for-non-eu').val())
      }

      $vatProcess.hide();
      if (vatStateText === $vatProcess.text()) {
        return;
      }
      vatStateText = $vatProcess.text();
      let $vatInput = $('.validate-eu-vat-number').find('input#billing_eu_vat_number');
      if ($vatInput.is(':disabled')) {
        return;
      }

      let vatNoticeType = 'info';
      if ($vatProcess.hasClass('alg-wc-eu-vat-not-valid')) {
        vatNoticeType = 'error'
      }
      if ($vatProcess.hasClass('alg-wc-eu-vat-valid')) {
        vatNoticeType = 'success'
      }

      newNotice(vatStateText, vatNoticeType)
    });

    $(document).on('stripeError', function () {
      console.log('stripe error');
      addWcErrorsAsNotices($('.woocommerce-error')) // Observer should handle it.
    });

    $('.js-reduce-session-cart-items-count').on('click', function () {
      reduceSessionCartItemCount();
    });

    $('.restore-item').on('click', function () {
      increaseSessionCartItemCount();
    });
  });
}

export function initBillingCompanyFields() {

  // Editing fields
  $(document).ready(function () {
    $(document).on('change', '.js-hide-vat-for-non-eu', function () {
      toggleVatFieldBasedOnCountry($(this).val())
    })

    $(document).on('focusout', 'input[type=text].js-ajax-update-checkout-session', function () {
      updateCheckoutSession($(this).prop('name'), $(this).val())
    })
    $(document).on('change', 'input[type=checkbox].js-ajax-update-checkout-session', function () {
      updateCheckoutSession($(this).prop('name'), $(this).prop('checked'))
    })

    $(document).on('change', 'select.js-session-autofill-checkout', function () {
      let $this = $(this);
      let name = $this.prop('name');

      cacheCheckoutUserInputInSession(name, 'select', $this.val());
    });

    $(document).on('change', 'input[type=checkbox].js-session-autofill-checkout', function () {
      let $this = $(this);
      let name = $this.prop('name');

      cacheCheckoutUserInputInSession(name, 'checkbox', $this.prop('checked'));
    });

    $(document).on('focusout', 'input.js-session-autofill-checkout', function () {
      let $this = $(this);
      let type = $this.prop('type');
      if (type === 'checkbox') {
        return;
      }

      cacheCheckoutUserInputInSession($this.prop('name'), type, $this.val());
    });

    toggleIsCompanyFields();
    fillFieldsWithSessionData();

    $(document.body).on('update_checkout', function () {
      if (!$vatNrInputContainer.length) {
        return;
      }

      let $vatProcess = $('#alg_wc_eu_vat_progress');

      $vatNrInputContainer.removeClass('woocommerce-validated');
      if ($vatProcess.hasClass('alg-wc-eu-vat-not-valid')) {
        $vatNrInputContainer.addClass('woocommerce-invalid');
      }
      if ($vatProcess.hasClass('alg-wc-eu-vat-valid')) {
        $vatNrInputContainer.removeClass('woocommerce-invalid');
      }
    });

  });

  $('form.woocommerce-checkout label').each(function () {
    let label = $(this);
    let inputField = label.siblings('.woocommerce-input-wrapper').find('input');
    let fromLabelTopJs = '0.2rem';

    // Function to update label position based on input value
    function updateLabelPosition() {
      if ($.trim(inputField.val())) {
        label.css('margin-top', fromLabelTopJs);
      } else {
        label.css('margin-top', '1rem');
      }
    }

    // Initial check
    setTimeout(function () {
      updateLabelPosition();
    }, 250);

    // Remove previous event bindings to avoid duplicates
    inputField.off('focusin focusout');

    inputField.on('focusin', function () {
      label.css('margin-top', fromLabelTopJs);
    });

    inputField.on('focusout', updateLabelPosition);
  });


  $('#billing_eu_vat_number').on('input', function () {
    var value = $(this).val();
    $(this).val(value.replace(/[^A-Za-z0-9]/g, ''));
  });

  if ($isBillingCompanyInput.length) {
    $('.for-companies, #billing_eu_vat_number_field').hide();
    $isBillingCompanyInput.on('change', function () {
      toggleIsCompanyFields()
      jQuery(document.body).trigger('update_checkout');
    });
  }

  $(document).on('click', '.js-change-wc-payment-method', function (e) {
    e.preventDefault(); // Prevent default link behavior
    // Uncheck all payment method inputs
    $('.js-payment-method-input').prop('checked', false);

    let $this = $(this);
    let target = $this.attr('href');
    let $paymentInput = $(target).find('.js-payment-method-input');
    // Check the first input in the $paymentInput collection
    $paymentInput.prop('checked', true);
    $paymentInput.trigger('click');

  })
}
